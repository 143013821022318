import React from 'react'
import { useRouter } from 'next/router'
import { CategoriaNode } from '@gql/graphql'
import { useTrans } from 'hooks'
import { PlaceholderReact } from '@components/molecules'
import { NewsletterSection, Page, Stripe, TabProdotti } from 'components/organisms'
import styles from './HomeTemplate.module.sass'
import { JsonLd } from '@components/atoms'

interface Props {
  pagina?: any
  heroContent?: any
  mainContent?: any
  prodottiTopDelMese?: CategoriaNode[]
  categorieHome?: CategoriaNode[]
}

const HomeTemplate = (props: Props) => {
  const {
    pagina = {},
    heroContent = undefined,
    mainContent = undefined,
    prodottiTopDelMese = [],
    categorieHome = [],
  } = props

  const t = useTrans()
  const router = useRouter()

  const tabProdottiItem = {
    name: t(`Abbiamo selezionato questi prodotti per te ${process.env.SITE}`),
    description: t(
      `<p>Descrizione <b>formattata</b> prodotti top del mese</p> ${process.env.SITE}`
    ),
    children: prodottiTopDelMese,
  }

  const newsletterItem = {
    name: t(`newsletter_section_title_${process.env.SITE_ID}_${router.locale}`),
    description: t(`newsletter_section_description_${process.env.SITE_ID}_${router.locale}`),
    image: `/${process.env.SITE}/newsletter_section.jpg`,
  }

  return (
    <Page pagina={pagina} item={pagina} className={`${styles.root}`}>
      <main>
        <PlaceholderReact
          contentTypeId={pagina.model}
          objectId={pagina.pk}
          pkey={'hero'}
          serverSideContent={heroContent}
        />
        <Stripe items={categorieHome} className={styles.stripe} />
        <TabProdotti correlati={true} item={tabProdottiItem} />
        <PlaceholderReact
          contentTypeId={pagina.model}
          objectId={pagina.pk}
          pkey={'main'}
          serverSideContent={mainContent}
        />
        <NewsletterSection item={newsletterItem} variant="home" />
      </main>
      <JsonLd type="organization" />
    </Page>
  )
}

export default HomeTemplate
