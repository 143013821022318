import React from 'react'
import { initializeApollo } from '@gql/client'
import { HomeDataDocument, PaginaDocument } from '@gql/graphql'
import { NextWrapper } from 'data-fetching-wrapper'
import HomeTemplate from '@components/templates/HomeTemplate/HomeTemplate'
import { normalizeGraphQLData } from '@utils/safe'

const HomePage = ({ pagina, heroContent, mainContent, prodottiTopDelMese, categorieHome }) => (
  <HomeTemplate
    pagina={pagina}
    heroContent={heroContent}
    mainContent={mainContent}
    prodottiTopDelMese={prodottiTopDelMese}
    categorieHome={categorieHome}
  />
)

export const getStaticProps = NextWrapper.getStaticProps(
  async ({ preview, previewData, locale }) => {
    const client = initializeApollo(locale)

    const headers = preview && previewData?.headers ? { cookie: previewData.headers } : {}

    const {
      data: { pagina },
    } = await client.query({
      query: PaginaDocument,
      variables: { chiave: 'homepage', preview },
      context: {
        headers,
      },
    })

    if (!pagina) {
      return {
        notFound: true,
      }
    }

    const heroContent = pagina?.pagebuilders?.find((x) => x.key === 'hero') ?? null
    const mainContent = pagina?.pagebuilders?.find((x) => x.key === 'main') ?? null

    const {
      data: { prodottiTopDelMese, categorie },
    } = await client.query({
      query: HomeDataDocument,
    })

    const categorieHome = normalizeGraphQLData(categorie)

    return {
      props: {
        pagina,
        heroContent,
        mainContent,
        prodottiTopDelMese,
        categorieHome,
      },
      revalidate: 60 * 10,
    }
  },
  '/'
)

export default HomePage
